import { Component, OnInit, ViewChild, ElementRef, Output, Inject } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ProcessDataSource } from '../../services/process.datasource';
import { ProcessService } from '../../services/process.service';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ProcessTypeService } from 'src/app/services/proces-type.service';
import { CompanyRepresentativeService } from 'src/app/services/companyRepresentative.service';
import { CompanyRepresentativeStatus } from 'src/app/entities/states/companyRepresentativeStatus';
import { CompanyRepresentativesStatusName } from 'src/app/entities/util';
import { CompanyService } from 'src/app/services/company.service';
import { CompanyRepresentative } from 'src/app/entities/companyRepresentative';
import { Company } from 'src/app/entities/company';
import { JwtHelperService } from '@auth0/angular-jwt';
import { YearService } from 'src/app/services/year.service';
export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'tas-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  displayedColumns: string[] = ['link', 'name', 'status', 'due_date', 'started_on', 'last_modified'];
  displayedColumnsSmall: string[] = ['link', 'name', 'status'];
  currentYear = (new Date().getFullYear()-1).toString()
  currentYearInt = new Date().getFullYear()-1
  displayedYears: string[] = [];
  isCurrentYearActive = true;
  dataSource: ProcessDataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  animal: string;
  name: string;
  companies: Company[];
    statusNames = CompanyRepresentativesStatusName
    companyRepresentative: CompanyRepresentative
  constructor(private processService: ProcessService, private router: Router, private route: ActivatedRoute, 
    public dialog: MatDialog, private companyRepresentativeService: CompanyRepresentativeService,public yearService: YearService,
    private companyService: CompanyService,  public jwtHelper: JwtHelperService) {
      this.companyRepresentative = new CompanyRepresentative()
      this.companyRepresentative.username = this.getUserFromToken()
      this.companyRepresentative.email = this.getUserFromToken()
  }
  cellClicked(element){
  }
  sendRequest(){
    this.companyRepresentativeService.sendRequest(this.companyRepresentative).subscribe(val=>{
      this.companyRepresentative.status = CompanyRepresentativeStatus.REQUESTED
    })
  }
  isRepValid(){
    return (this.companyRepresentative.name &&
      this.companyRepresentative.surname &&
      this.companyRepresentative.address &&
      this.companyRepresentative.company &&
      this.companyRepresentative.telephone)
  }
  ngOnInit() {
    this.yearService.getList("year").subscribe(val =>{
      val.forEach(element => {
        if(element.visible)
        this.displayedYears.push(element.year)
        if (element.year===this.currentYear){
          this.isCurrentYearActive = element.isActive
        }
        
      });
    })
    this.companyRepresentativeService.getCompanyRepresentative().subscribe(val=>{
 
      if (val!==null){
        this.companyRepresentative = val
        if (this.route.snapshot.paramMap.get('year')) {
          this.currentYear = this.route.snapshot.paramMap.get('year')
        }
        this.dataSource = new ProcessDataSource(this.processService)
        this.dataSource.loadProcessess(this.currentYear, '', 'asc', 'name', 0, 10);
      }else{
        this.companyService.getCompanyList().subscribe(companies=>{
          this.companies = companies
        })
      }
      
    })
  }

  handleSelection(event) {
    this.currentYear = event.value;
    this.yearService.getList("year").subscribe(val =>{
      this.displayedYears=[]
      val.forEach(element => {
        if(element.visible)
        this.displayedYears.push(element.year)
        if (element.year===this.currentYear){
          this.isCurrentYearActive = element.isActive
        }
        
      });
    })
    this.dataSource.loadProcessess(this.currentYear, '', 'asc', 'name', 0, this.paginator.pageSize);
  }

  ngAfterViewInit() {
    if (this.companyRepresentative.status===CompanyRepresentativeStatus.SUSPENDED){
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadProcessPage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadProcessPage())
      )
      .subscribe();
    }
  }
  getUserFromToken() {
    if (this.jwtHelper.decodeToken(localStorage.getItem('access_token')))
      return this.jwtHelper.decodeToken(localStorage.getItem('access_token')).username;
    return null;
  }

  loadProcessPage() {
    this.dataSource.loadProcessess(
      this.currentYear,
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NewProcessDialog, {
      width: '600px',
      maxHeight: 'calc(100vh - 90px)',
      height: 'auto',
      data: { year: this.currentYear }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

}


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'new-process-dialog.html',
})
export class NewProcessDialog implements OnInit {
  year: string;
  processes = []
  constructor(
    public dialogRef: MatDialogRef<NewProcessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router,
    private processService: ProcessService, private processTypeService: ProcessTypeService) {
    this.year = data['year'];
  }

  ngOnInit() {
    this.processTypeService.getProcessTypes(this.year).subscribe(
      data => {
        this.processes = data.records
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  navigateToProcess(report) {
    this.dialogRef.close();
    var completeProcess = {
      'key': '',
      'name': '',
      'year': '',
      'stages': []
    };
    completeProcess.key = report.key
    completeProcess.name = report.name
    completeProcess.year = this.year
    completeProcess['status'] = 'In Progress (Draft)';
    completeProcess['progress'] = Math.floor(Math.random() * 99) + 1;
    completeProcess.stages = [];
    this.processService.addProcess(completeProcess).subscribe
      (data => {
        this.router.navigate(['process/' + data.id]);
      });
  }


}

